import { IncomeRange, Lem } from "./../interfaces/lem-cmm-interface";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { map, Observable } from "rxjs";
import { HttpResponse } from "../interfaces/http-response-interface";

@Injectable({
  providedIn: "root",
})
export class LemService {
  refreshIncomeRangeTable$: EventEmitter<boolean> = new EventEmitter();
  refreshDataTable$: EventEmitter<{ incomeRangeId: number; incomeType: string }> =
    new EventEmitter();
  url = `${environment.api}/lem`;
  constructor(private http: HttpClient) {}

  getAll(
    incomeRangeId: number,
    country: string = "",
    size: number = 15,
    page: number = 0,
    sort: string = "name,desc"
  ): Observable<HttpResponse<Lem>> {
    country = country === "ALL" ? "" : country;
    let data = { size, page, sort, country };
    const headers = new HttpHeaders().append("Country", country);
    const params = new HttpParams({ fromObject: data });
    return this.http
      .get<HttpResponse<Lem>>(`${this.url}/${incomeRangeId}`, {
        params,
        headers,
        observe: "response",
      })
      .pipe(
        map((data) => {
          data.body!.page = {
            number: 0,
            size: 15,
            totalElements: 4,
            totalPages: 1,
          };
          data.body!.typeCalc = data.headers.get("X-METHOD-CALCULATION") as any;
          return data.body as HttpResponse<Lem>;
        })
      );
  }

  createNew(item: Lem): Observable<HttpResponse<Lem>> {
    return this.http.post<HttpResponse<Lem>>(this.url, item);
  }

  updateItem(item: Lem): Observable<HttpResponse<Lem>> {
    const url = `${this.url}/${item.lemCalculationId}`;
    return this.http.put<HttpResponse<Lem>>(url, item);
  }

  getIncomeRange(
    country: string,
    size: number = 15,
    page: number = 0
  ): Observable<HttpResponse<IncomeRange>> {
    country = country === "ALL" ? "" : country;
    let data = { size, page, country };
    const params = new HttpParams({ fromObject: data });
    const url = country ? `${environment.api}/income/filter` : `${environment.api}/income`;

    return this.http.get<HttpResponse<IncomeRange>>(url, { params });
  }

  saveIncomeRange(incomeRange: Partial<IncomeRange>): Observable<HttpResponse<IncomeRange>> {
    const url = `${environment.api}/income`;

    return this.http.post<HttpResponse<IncomeRange>>(url, { ...incomeRange });
  }

  updateIncomeRange(
    incomeRangeId: number,
    incomeRange: Partial<IncomeRange>
  ): Observable<HttpResponse<IncomeRange>> {
    const url = `${environment.api}/income/${incomeRangeId}`;

    return this.http.put<HttpResponse<IncomeRange>>(url, { ...incomeRange });
  }

  refreshIncomeRangeTable(): void {
    this.refreshIncomeRangeTable$.emit(true);
  }

  refreshDataTable(incomeRangeId: number, incomeType: string): void {
    this.refreshDataTable$.emit({ incomeRangeId, incomeType });
  }
}

import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private zone: NgZone
  ) {}

  show(text: string, transale = true): void {
    const translator = this.translate.instant(['ALERT.CLOSE', text]);
    const msm = transale ? translator[text] : text;
    this.zone.run(() => {
      this.snackBar.open(msm, translator['ALERT.CLOSE'], {
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        duration: 3000,
      });
    });
  }

  showSnack(text: string, transale = true): MatSnackBarRef<TextOnlySnackBar> {
    const translator = this.translate.instant(['ALERT.CLOSE', text]);
    const msm = transale ? translator[text] : text;
    let snack: MatSnackBarRef<TextOnlySnackBar>;
    this.zone.run(() => {
      snack = this.snackBar.open(msm, translator['ALERT.CLOSE'], {
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        duration: 10000,
      });
    });
    return snack!;
  }
}

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { KeycloakProfile } from "keycloak-js";
import { catchError, Observable, throwError } from "rxjs";
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";

class HttpError {
  static BadRequest = 400;
  static Unauthorized = 401;
  static Forbidden = 403;
  static NotFound = 404;
  static TimeOut = 408;
  static Conflict = 409;
  static InternalServerError = 500;
}

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}
  user: KeycloakProfile = {} as KeycloakProfile;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newHeaders = req.headers;
    let currentLang = this.translate.currentLang;
    let newParams = new HttpParams();
    let newReq: HttpRequest<any>;
    this.spinner.show();
    this.getUserMail();
    newHeaders =
      req.url.includes(environment.api) && typeof this.user.username === "string"
        ? newHeaders
            .append("Accept-Language", [currentLang || "es"])
            .append("X-USER-ID", this.user.username)
        : newHeaders.append("Accept-Language", [currentLang || "es"]);

    if (req.url.includes(environment.strapi_url)) {
      newParams = req.url.includes("?locale=")
        ? new HttpParams()
        : new HttpParams({ fromObject: { locale: currentLang || "es" } });

      newReq = req.clone({ headers: newHeaders, params: newParams });
    } else {
      newReq = req.clone({ headers: newHeaders });
    }

    return next.handle(newReq).pipe(
      catchError((error) => {
        this.spinner.hide();
        switch (error.status) {
          case HttpError.Unauthorized:
            this.router.navigate(["/error/" + error.status]);
            break;

          case HttpError.TimeOut:
            this.router.navigate(["/error/" + error.status]);
            break;

          case HttpError.Forbidden:
            this.router.navigate(["/error/" + error.status]);
            break;

          case HttpError.InternalServerError:
            this.router.navigate(["/error/" + error.status]);
            break;
        }
        return throwError(() => error);
      })
    );
  }

  async getUserMail(): Promise<void> {
    this.user = await this.authService.getUserProfile();
  }
}

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { RulesServices } from './../services/rules.service';
import { catchError, first } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class RulesResolvers implements Resolve<unknown> {
  constructor(private ruleService: RulesServices) {}

  resolve(): unknown {
    return this.ruleService
      .getAllRules()
      .pipe(first())
      .pipe(
        catchError((err) => {
          return of({});
        })
      );
  }
}

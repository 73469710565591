import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { EMPTY, expand, finalize, map, Observable, reduce, tap } from "rxjs";
import { CountryStrapi, CountryStrapiHttpResponse } from "../interfaces/country-strapi-interface";
import { CustomerTypeStrapiHttpResponse } from "../interfaces/customer-type-strapi-interface";
import { SalesTypeStrapiHttpResponse } from "../interfaces/sales-type-strapi-interface";
import { QualificationStrapiHttpResponse } from "../interfaces/qualification-strapi-interface";
import { IncomeSourceStrapiHttpResponse } from "../interfaces/income-source-strapi-interface";
import { TranslateService } from "@ngx-translate/core";
import { MaritalStatusStrapiInterface } from "@interfaces/marital-status-strapi-interface";
import { PaymentFrequencyInterface } from "@interfaces/payment-frequency-interface";
import { HousingSituationsStrapiInterface } from "@interfaces/housing-situations-strapi-interface";
import { OccupationsStrapiInterface } from "@interfaces/ocupations-strapi-interface";
import { EmploymentStrapiInterface } from "@interfaces/employment-strapi-interface";
import { WorstCurrentInterface } from "@interfaces/worst-current-interface";
import { PromotionalPlansResponse } from "@interfaces/promotional-plans";
import { RegionsStrapiInterface } from "@interfaces/regions-strapi-interface";

@Injectable({
  providedIn: "root",
})
export class StrapiService {
  url = environment.strapi_url;
  params = new HttpParams({
    fromObject: {
      language: this.translate.currentLang,
    },
  });

  constructor(private http: HttpClient, private translate: TranslateService) {}

  getAllPromotionalPlans(lang: string): Observable<PromotionalPlansResponse> {
    let storageCountry = JSON.parse(localStorage.getItem("country") + "") as CountryStrapi;
    let actualInfoCountryLang = `${lang}-${storageCountry.attributes.CountryCode}`;
    const url = `${this.url}/promotional-plans?locale=${actualInfoCountryLang}&populate=*`;
    return this.http.get<PromotionalPlansResponse>(url, { ...this.params });
  }

  getPromotionalPlan(slug: string, lang: string): Observable<PromotionalPlansResponse> {
    let storageCountry = JSON.parse(localStorage.getItem("country") + "") as CountryStrapi;
    let actualInfoCountryLang = `${lang}-${storageCountry.attributes.CountryCode}`;
    const url = `${this.url}/promotional-plans?locale=${actualInfoCountryLang}&populate=*&filters[sales_type][slug]=${slug}`;
    return this.http.get<PromotionalPlansResponse>(url, { ...this.params });
  }

  getCountries(): Observable<CountryStrapiHttpResponse> {
    let page = 1;
    let pageCount = 0;
    let total = 0;
    return this.getCountriesByPage(page++).pipe(
      tap(({ meta }) => {
        pageCount = meta.pagination.pageCount;
        total = meta.pagination.total;
      }),
      expand(() => (page <= pageCount ? this.getCountriesByPage(page++) : EMPTY)),
      reduce((acc: CountryStrapi[], res) => acc.concat(res.data), []),
      map((acc) => ({
        data: acc,
        meta: { pagination: { page: 1, pageCount: 1, pageSize: total, total } },
      }))
    );
  }

  private getCountriesByPage(page: number): Observable<CountryStrapiHttpResponse> {
    const locale = this.translate.currentLang || "en";
    return this.http.get<CountryStrapiHttpResponse>(
      `${this.url}/countries?locale=${locale}&pagination[pageSize]=100&pagination[page]=${page}`
    );
  }

  getCustomerType(): Observable<CustomerTypeStrapiHttpResponse> {
    const url = `${this.url}/customer-types`;

    return this.http.get<CustomerTypeStrapiHttpResponse>(url, { ...this.params });
  }

  getSalesType(): Observable<SalesTypeStrapiHttpResponse> {
    const lang = localStorage.getItem("defaultlang");
    const storageCountry = JSON.parse(localStorage.getItem("country") + "") as CountryStrapi;
    const locale = `${lang}-${storageCountry.attributes.CountryCode}`;
    const url = `${this.url}/sales-types?locale=${locale}&populate=*`;
    return this.http.get<SalesTypeStrapiHttpResponse>(url);
  }

  getLemQualification(): Observable<QualificationStrapiHttpResponse> {
    const url = `${this.url}/credit-ratings`;

    return this.http.get<QualificationStrapiHttpResponse>(url, { ...this.params });
  }

  getIncomeSource(): Observable<IncomeSourceStrapiHttpResponse> {
    const url = `${this.url}/income-source-classifications`;

    return this.http.get<IncomeSourceStrapiHttpResponse>(url, { ...this.params });
  }

  getMaritalStatus(): Observable<MaritalStatusStrapiInterface> {
    const url = `${this.url}/marital-statuses`;
    return this.http.get<MaritalStatusStrapiInterface>(url, { ...this.params });
  }
  getPaymentFrequency(): Observable<PaymentFrequencyInterface> {
    const url = `${this.url}/payment-frequencies`;
    return this.http.get<PaymentFrequencyInterface>(url, { ...this.params });
  }
  getResidentialStatus(): Observable<HousingSituationsStrapiInterface> {
    const url = `${this.url}/housing-situations`;
    return this.http.get<HousingSituationsStrapiInterface>(url, { ...this.params });
  }
  getOccupations(pageNumber?: number): Observable<OccupationsStrapiInterface> {
    let url = `${this.url}/occupations`;
    if (pageNumber) {
      url += `?&pagination[page]=${pageNumber}`;
    }
    return this.http.get<OccupationsStrapiInterface>(url, { ...this.params });
  }
  getEmploymentStatuses(): Observable<EmploymentStrapiInterface> {
    const url = `${this.url}/employment-statuses`;
    return this.http.get<EmploymentStrapiInterface>(url, { ...this.params });
  }
  getWorstCurrentEver(): Observable<WorstCurrentInterface> {
    const url = `${this.url}/credit-ratings`;
    return this.http.get<WorstCurrentInterface>(url, { ...this.params });
  }

  getRegions(): Observable<RegionsStrapiInterface> {
    let lang = this.translate.currentLang;
    let storageCountry = JSON.parse(localStorage.getItem("country") + "") as CountryStrapi;
    let actualInfoCountryLang = `${lang}-${storageCountry.attributes.CountryCode}`;
    return this.http.get<RegionsStrapiInterface>(
      `${this.url}/regions?locale=${actualInfoCountryLang}`
    );
  }
}

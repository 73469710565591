import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Rule } from "@pages/rules/interfaces/rule-interface";
import { Observable, Subject, map } from "rxjs";
import { RuleParameters } from "@pages/rules/interfaces/rule-parameters-interface";
import { HttpResponse, HttpResponseUniqueItem } from "../interfaces/http-response-interface";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class RulesServices {
  url = `${environment.api}/rules`;
  repeatedRuleSubject: Subject<string> = new Subject();

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  getAllRules(
    size: number = 15,
    page: number = 0,
    country: string = "",
    sort: string = "name,desc"
  ): Observable<HttpResponse<Rule>> {
    country = country === "ALL" ? "" : country;
    const url = country ? `${this.url}/filter` : this.url;
    let data = { size, page, sort, country };
    const params = new HttpParams({ fromObject: data });

    return this.http.get<HttpResponse<Rule>>(url, { params });
  }

  getAllRulesList(): Observable<HttpResponse<Rule>> {
    return this.http.get<HttpResponse<Rule>>(this.url);
  }

  changeRuleStatus(ruleId: number, status: string): Observable<HttpResponse<Rule>> {
    const url = `${this.url}/${ruleId}/status`;

    return this.http.put<HttpResponse<Rule>>(url, { status });
  }

  generateReport(start: string, end: string): Observable<any> {
    let country = JSON.parse(localStorage.getItem("country") + "");
    const headers = new HttpHeaders().append("Country", `${country?.attributes?.CountryCode}`);
    const url = `${this.url}/evaluation-details?startingDate=${start}&endingDate=${end}`;
    return this.http.get<HttpResponse<any>>(url, { headers });
  }

  generateReportAnalysis(start: string, end: string): Observable<any> {
    let country = JSON.parse(localStorage.getItem("country") + "");
    const headers = new HttpHeaders().append("Country", `${country?.attributes?.CountryCode}`);
    const url = `${this.url}/evaluation-analysis?startingDate=${start}&endingDate=${end}`;
    return this.http.get<Blob>(url, { headers, responseType: "blob" as "json" });
  }

  changeParameterStatus(
    ruleId: number,
    parameterId: number,
    status: string
  ): Observable<HttpResponse<Rule>> {
    const url = `${this.url}/${ruleId}/parameters/${parameterId}/status`;

    return this.http.put<HttpResponse<Rule>>(url, { status });
  }

  getRepeatedRule(value: string): void {
    this.repeatedRuleSubject.next(value);
  }

  getRepeatedRuleAsObservable(): Observable<string> {
    return this.repeatedRuleSubject.asObservable();
  }

  getRuleByName(
    name: string,
    country = "",
    size: number = 15,
    page: number = 0,
    sort: string = "name,desc"
  ): Observable<HttpResponse<Rule>> {
    country = country === "ALL" ? "" : country;
    let data = { size, page, sort, country, startsWith: name.trim().toUpperCase() };
    const params = new HttpParams({ fromObject: data });
    const url = `${this.url}/filter?`;
    return this.http.get<HttpResponse<Rule>>(url, { params });
  }

  saveRule(rule: Rule): Observable<Rule> {
    const url = `${this.url}`;
    return this.http.post<Rule>(url, rule);
  }

  getRuleDetails(ruleId: number): Observable<HttpResponseUniqueItem<Rule>> {
    const url = `${this.url}/${ruleId}`;
    return this.http.get<HttpResponseUniqueItem<Rule>>(url).pipe(
      map((res) => {
        res.page = {
          number: 0,
          size: 15,
          totalElements: 4,
          totalPages: 1,
        };
        res._embedded = {
          data: res as any,
        };
        return res;
      })
    );
  }

  setUpRule(ruleId: number, parameters: RuleParameters): Observable<Rule> {
    const url = `${this.url}/${ruleId}/parameters`;

    return this.http.post<Rule>(url, { ...parameters });
  }

  updateRuleDescription(rule: Rule): Observable<Rule> {
    const url = `${this.url}/${rule.ruleId}/rule`;

    return this.http.put<Rule>(url, rule);
  }

  updateRuleScoringDescription(rule: Rule): Observable<Rule> {
    const url = `${environment.api}/scoring/rules/${rule.ruleId}/rule`;

    return this.http.put<Rule>(url, {
      translation: this.translate.currentLang,
      description: rule.description,
    });
  }

  updateParam(ruleId: number, param: RuleParameters): Observable<Rule> {
    const data = {
      clientType: param.clientType,
      saleType: param.saleType,
      operator: param.operator,
      value1: param.value1,
      value2: param.value2 || "",
      resolution: param.resolution,
    };
    const url = `${this.url}/${ruleId}/parameters/${param.parameterId}`;
    return this.http.put<Rule>(url, data);
  }
}

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AlertService } from "../services/alert.service";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
import { AuthService } from "../services/auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from "@angular/material/snack-bar";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGuard extends KeycloakAuthGuard {
  userRole: string[] = [];
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private alert: AlertService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar
  ) {
    super(router, keycloak);
  }

  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }
    // Get the roles required from the route.
    const requiredRoles = route.data["roles"];
    this.userRole = await this.authService.getUserRole();

    // Roles that has no access to Smart-Credit
    const restrictedRoles = ["branchmanager_viewer"];

    const hasRestrictedRole = this.userRole.some((role) => restrictedRoles.includes(role));

    if (hasRestrictedRole) {
      this.spinner.hide();
      await firstValueFrom(
        this.snackBar
          .open("User without valid role | Usuario sin rol válido", "Close | Cerrar", {
            horizontalPosition: "center",
            verticalPosition: "bottom",
          })
          .afterDismissed()
      );
      this.keycloak.logout(window.location.origin);
      return false;
    }

    let onlyUsers = [
      "application_admin",
      "corporate_operations",
      "credit_and_collections_manager",
      "deputy_credit_manager",
      "head_of_credits",
      "credit_supervisor",
      "credit_analyst",
      "committee",
      "parametrizaciones_pais",
    ];

    let ctrlRole = onlyUsers.filter(
      (role: string) => this.userRole.includes(role) && role !== "default-roles-credits"
    );

    if (ctrlRole.length === 0) {
      this.spinner.hide();
      await firstValueFrom(
        this.snackBar
          .open("User without valid role | Usuario sin rol válido", "Close | Cerrar", {
            horizontalPosition: "end",
            verticalPosition: "bottom",
          })
          .afterDismissed()
      );
      this.keycloak.logout(window.location.origin);
      return false;
    }

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    return requiredRoles.some((role) => this.roles.includes(role)) || this.router.parseUrl("/home");
  }
}

import { SalesTypeResolvers } from "./resolvers/sales-type.resolver";
import { CustomerTypeResolvers } from "./resolvers/customer-type.resolver";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RulesResolvers } from "./resolvers/rules.resolver";
import { AuthGuard } from "./guards/auth.guard";
import { UsersResolvers } from "./resolvers/users.resolver";
import { CountriesResolver } from "./resolvers/countries.resolver";
import { TypeProductModule } from "@pages/type-product/type-product.module";

const routes: Routes = [
  {
    path: "home",
    loadChildren: () => import("@pages/home/home.module").then((m) => m.HomeModule),
    canActivate: [AuthGuard],
    data: {
      roles: [],
    },
  },
  {
    path: "error/:error",
    loadChildren: () => import("@pages/error-page/error-page.module").then((m) => m.ErroPageModule),
    canActivate: [AuthGuard],
    data: {
      roles: [],
    },
  },
  {
    path: "users",
    loadChildren: () => import("@pages/users/users.module").then((m) => m.UsersModule),
    resolve: {
      usersData: UsersResolvers,
    },
    canActivate: [AuthGuard],
    data: {
      roles: ["application_admin"],
    },
  },
  {
    path: "rules",
    loadChildren: () => import("@pages/rules/rules.module").then((m) => m.RulesModule),
    canActivate: [AuthGuard],
    data: {
      roles: ["application_admin", "corporate_operations"],
    },
  },
  {
    path: "parameters",
    loadChildren: () =>
      import("@pages/parameters/parameters.module").then((m) => m.ParametersModule),
    canActivate: [AuthGuard],
    data: {
      roles: ["application_admin", "corporate_operations"],
    },
  },
  {
    path: "financial-products",
    loadChildren: () =>
      import("@pages/financial-products/financial-products.module").then(
        (m) => m.FinancialProductsModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ["application_admin", "corporate_operations", "parametrizaciones_pais"],
    },
  },
  {
    path: "type-products",
    loadChildren: () =>
      import("@pages/type-product/type-product.module").then((m) => m.TypeProductModule),
  },
  {
    path: "customer-payments",
    loadChildren: () =>
      import("@pages/customer-payment/customer-payment.module").then(
        (m) => m.CustomerPaymentModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ["application_admin", "corporate_operations"],
    },
    resolve: { countries: CountriesResolver },
  },
  {
    path: "rule-maintenance",
    loadChildren: () =>
      import("@pages/rule-maintenance/rule-maintenance.module").then(
        (m) => m.RuleMaintenanceModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ["application_admin", "corporate_operations"],
    },
    resolve: { countries: CountriesResolver, rules: RulesResolvers },
  },
  {
    path: "params",
    loadChildren: () =>
      import("@pages/modificacion-parametros-regla/modificacion-parametros-regla.module").then(
        (m) => m.ModificacionParametrosReglaModule
      ),
    resolve: {
      customerType: CustomerTypeResolvers,
      salesType: SalesTypeResolvers,
    },
    canActivate: [AuthGuard],
    data: {
      roles: ["application_admin", "corporate_operations"],
    },
  },
  {
    path: "create-reports",
    loadChildren: () =>
      import("@pages/create-reports/create-reports.module").then((m) => m.CreateReportsModule),
    canActivate: [AuthGuard],
    data: {
      roles: [
        "application_admin",
        "credit_and_collections_manager",
        "deputy_credit_manager",
        "head_of_credits",
        "credit_supervisor",
        "credit_analyst",
        "corporate_operations",
      ],
    },
  },
  {
    path: "rules-scoring",
    loadChildren: () =>
      import("@pages/calculo-scoring/calculo-scoring.module").then((m) => m.CalculoScoringModule),
    canActivate: [AuthGuard],
    data: {
      roles: ["application_admin", "corporate_operations"],
    },
    resolve: {
      customerType: CustomerTypeResolvers,
      salesType: SalesTypeResolvers,
      countries: CountriesResolver,
    },
  },
  {
    path: "update-rules-scoring",
    loadChildren: () =>
      import("@pages/modificacion-parametros-regla/modificacion-parametros-regla.module").then(
        (m) => m.ModificacionParametrosReglaModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ["application_admin", "corporate_operations"],
    },
    resolve: {
      customerType: CustomerTypeResolvers,
      salesType: SalesTypeResolvers,
    },
  },
  {
    path: "cmm",
    loadChildren: () => import("@pages/cmm/cmm.module").then((m) => m.CmmModule),
    canActivate: [AuthGuard],
    data: {
      roles: ["application_admin", "corporate_operations"],
    },
  },
  {
    path: "lem",
    loadChildren: () => import("@pages/lem/lem.module").then((m) => m.LemModule),
    canActivate: [AuthGuard],
    data: {
      roles: ["application_admin", "corporate_operations"],
    },
  },
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "**", redirectTo: "home" },
];

const resolvers = [
  RulesResolvers,
  CustomerTypeResolvers,
  SalesTypeResolvers,
  UsersResolvers,
  CountriesResolver,
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [...resolvers],
})
export class AppRoutingModule {}

import { AfterViewInit, Injectable, OnInit } from "@angular/core";
import { CountryStrapi } from "@interfaces/country-strapi-interface";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, Subject, take } from "rxjs";
import { StrapiService } from "./strapi.service";

@Injectable({
  providedIn: "root",
})
export class CountryService {
  private selectedCountry: CountryStrapi = {} as CountryStrapi;
  private selectedCountry$: Subject<CountryStrapi> = new Subject();
  private countryList$: Subject<CountryStrapi[]> = new Subject();
  private isAllOptionDisabled = true;

  public statusChange: boolean = false;

  countryList: CountryStrapi[] = [];
  i = 0;

  constructor(private strapi: StrapiService, private spinner: NgxSpinnerService) {}

  getAllCountries(): void {
    this.strapi.getCountries().subscribe({
      next: (countries: any) => {
        this.countryList = countries.data;
        this.orderCountries();
        this.countryList$.next(this.countryList);
        localStorage.setItem("countryList", JSON.stringify(this.countryList));
        this.spinner.hide();
      },
    });
  }

  orderCountries(): void {
    this.countryList.sort(function (a, b) {
      if (a.attributes.Name > b.attributes.Name) {
        return 1;
      }
      if (a.attributes.Name < b.attributes.Name) {
        return -1;
      }
      return 0;
    });
  }

  setValChange(value: boolean) {
    this.statusChange = value;
  }

  getValChange() {
    return this.statusChange;
  }

  set country(country: CountryStrapi) {
    this.selectedCountry = country;
    this.selectedCountry$.next(country);
  }

  get country(): CountryStrapi {
    return this.selectedCountry;
  }

  set enableAllOption(value: boolean) {
    this.isAllOptionDisabled = value;
  }

  get enableAllOption(): boolean {
    return this.isAllOptionDisabled;
  }

  getCountryAsObservable(): Observable<CountryStrapi> {
    return this.selectedCountry$.asObservable();
  }

  getCountryListAsObservable(): Observable<CountryStrapi[]> {
    return this.countryList$.asObservable();
  }
}

import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { AlertService } from "./alert.service";
import { map } from "rxjs";

interface ApiError {
  description: string;
  source: string;
  status: number;
  title: string;
  type: string;
}

@Injectable({
  providedIn: "root",
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(private spinner: NgxSpinnerService, private alert: AlertService) {}

  handleError(err: HttpResponse<any>) {
    this.spinner.hide();
    if (err instanceof HttpErrorResponse) {
      if (err.url?.includes(environment.strapi_url)) {
        this.alert.show("Error getting data from strapi");
      } else {
        err.error?.errors.map((error: ApiError) => this.alert.show(error.description));
      }
    }
  }
}

export const environment = {
  production: false,
  keycloak: {
    realm: "credits",
    url: "https://oauth-devqa-yfevihrw.unicomer.com/auth/",
    ssl_required: "external",
    clientId: "frontend-credit",
    confidential_port: "0",
  },
  kibana: {
    serverUrl: "https://79dcb527fa014de297bd2ee41306dfe2.apm.us-east1.gcp.elastic-cloud.com:443",
    serviceName: "smart-credit-frontend",
    environment: "local",
    distributedTracingOrigins: ["http://localhost:4200"],
  },
  name: "LOCAL",
  api: "https://credits-api-dev.unicomer.com/credits-smart-credit-backend-dev",
  strapi_url: "https://cms-devqa-ohiqfxwv.unicomer.com/cms/api",
};

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { catchError, first } from 'rxjs/operators';
import { of } from 'rxjs';
import { UsersService } from '../services/users.service';

@Injectable()
export class UsersResolvers implements Resolve<unknown> {
  constructor(private usersService: UsersService) {}

  resolve(): unknown {
    return this.usersService
      .getAllUsers()
      .pipe(first())
      .pipe(
        catchError((err) => {
          return of({});
        })
      );
  }
}

import { CmmService } from './cmm.service';
import { LemService } from './lem.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QualificationService } from './qualification.service';
import { StrapiService } from './strapi.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, MatSnackBarModule],
  exports: [TranslateModule],
})
export class SharedServicesModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedServicesModule,
      providers: [StrapiService, QualificationService, LemService, CmmService],
    };
  }
}

import { ParametersService } from "./parameters.service";
import { Cmm, CmmRange } from "./../interfaces/lem-cmm-interface";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable, map, take } from "rxjs";
import { HttpResponse } from "../interfaces/http-response-interface";

@Injectable({
  providedIn: "root",
})
export class CmmService {
  url = `${environment.api}/cmm`;
  constructor(private http: HttpClient) {}

  getAll(
    size: number = 15,
    page: number = 0,
    country: string,
    sort: string = "name,desc"
  ): Observable<HttpResponse<Cmm>> {
    country = country === "ALL" ? "" : country;
    let data = { size, page, sort, country };
    const params = new HttpParams({ fromObject: data });
    const headers = new HttpHeaders().append("Country", country);

    return this.http
      .get<HttpResponse<Cmm>>(this.url, { params, headers, observe: "response" })
      .pipe(
        map((data) => {
          data.body!.page = {
            number: 0,
            size: 15,
            totalElements: 4,
            totalPages: 1,
          };
          data.body!._embedded = data.body?._embedded || { data: [] };
          data.body!.typeCalc = data.headers.get("X-METHOD-CALCULATION") as any;
          return data.body as HttpResponse<Cmm>;
        })
      );
  }

  getRanges(country: string = ""): Observable<CmmRange> {
    const headers = new HttpHeaders().append("Country", country);
    const url = `${this.url}/range`;
    return this.http
      .get<CmmRange>(url, { headers })
      .pipe(take(1))
      .pipe(map((data: any) => data._embedded.data));
  }

  createNew(item: Cmm): Observable<HttpResponse<Cmm>> {
    return this.http.post<HttpResponse<Cmm>>(this.url, item);
  }

  createNewRange(item: CmmRange, country: string = ""): Observable<HttpResponse<CmmRange>> {
    const temp = {
      minimumScoring: item.minimumScoring,
      maximumScoring: item.maximumScoring,
      country: country,
    };
    const headers = new HttpHeaders().append("Country", country);
    return this.http.post<HttpResponse<CmmRange>>(`${this.url}/range`, temp, { headers });
  }

  updateItem(item: Cmm): Observable<HttpResponse<Cmm>> {
    const url = `${this.url}/${item.cmmParameterId}`;
    return this.http.put<HttpResponse<Cmm>>(url, item);
  }

  updateOnlyValue(item: any, country: string = "", isNew = false): Observable<HttpResponse<Cmm>> {
    const temp = {
      incomeType: item.incomeType,
      qualification: null,
      methodCalculation: item.methodCalculation,
      value: Number(item.value || 0),
      country,
      extra: Number(item.extra || 0),
      cmmParameterId: item.cmmParameterId,
      scoringRangeId: item.cmmScoringRangeId,
      status: "ACTIVE",
    };
    if (isNew) {
      const url = `${this.url}?scoringRangeId=${item.cmmScoringRangeId}`;
      return this.http.post<HttpResponse<Cmm>>(url, temp);
    } else {
      const url = `${this.url}/${item.cmmParameterId}`;
      return this.http.put<HttpResponse<Cmm>>(url, temp);
    }
  }

  updateRangeItem(item: CmmRange, country: string = ""): Observable<HttpResponse<CmmRange>> {
    const temp = {
      minimumScoring: item.minimumScoring,
      maximumScoring: item.maximumScoring,
      country: country,
    };
    const headers = new HttpHeaders().append("Country", country);
    const url = `${this.url}/range/${item.cmmScoringRangeId}`;
    return this.http.put<HttpResponse<CmmRange>>(url, temp, { headers });
  }

  groupBy(list: any, key: string, key2?: string): {} {
    return list.reduce((previousValue: any, currentValue: any) => {
      if (currentValue[key] in previousValue && key2) {
        if (
          !previousValue[currentValue[key]].every((elem: any) => elem[key2] === currentValue[key2])
        ) {
          (previousValue[currentValue[key2]] = previousValue[currentValue[key2]] || []).push(
            currentValue
          );
          return previousValue;
        }
      }

      (previousValue[currentValue[key]] = previousValue[currentValue[key]] || []).push(
        currentValue
      );

      return previousValue;
    }, {});
  }
}

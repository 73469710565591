import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { CustomerQualification } from '../interfaces/customer-qualification';
import { HttpResponse } from '../interfaces/http-response-interface';

@Injectable({
  providedIn: 'root',
})
export class QualificationService {
  url = `${environment.api}/calification`;

  constructor(private http: HttpClient) {}

  getAllQualifications(
    country: string = '',
    size: number = 15,
    page: number = 0,
    sort: string = 'qualification,desc'
  ): Observable<HttpResponse<CustomerQualification>> {
    country = country === 'ALL' ? '' : country;
    const url = country ? `${this.url}/filter` : this.url;
    let data = { size, page, sort, country };
    const params = new HttpParams({ fromObject: data });
    return this.http.get<HttpResponse<CustomerQualification>>(url, { params });
  }

  changeStatus(cq: CustomerQualification): Observable<HttpResponse<CustomerQualification>> {
    const url = `${this.url}/${cq.clientCalificationId}/status`;
    return this.http.put<HttpResponse<CustomerQualification>>(url, {
      status: cq.status,
    });
  }

  createQualification(cq: CustomerQualification): Observable<CustomerQualification> {
    const url = `${this.url}`;
    return this.http.post<CustomerQualification>(url, {
      calification: cq.qualification.toUpperCase(),
      initialRange: cq.initialRange,
      finalRange: cq.finalRange,
      countries: cq.countries,
    });
  }

  updateQualification(cq: CustomerQualification): Observable<CustomerQualification> {
    const url = `${this.url}/${cq.clientCalificationId}`;
    return this.http.put<CustomerQualification>(url, {
      calification: cq.qualification.toUpperCase(),
      initialRange: cq.initialRange,
      finalRange: cq.finalRange,
      countries: cq.countries,
    });
  }
}

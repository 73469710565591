import { StrapiService } from './../services/strapi.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { first } from 'rxjs/operators';

@Injectable()
export class SalesTypeResolvers implements Resolve<unknown> {
  constructor(private strapi: StrapiService) {}

  resolve(): unknown {
    return this.strapi.getSalesType().pipe(first());
  }
}

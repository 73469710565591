import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { KeycloakProfile } from "keycloak-js";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private keycloak: KeycloakService) {}

  async getUserRole(): Promise<string[]> {
    let isLoggedIn = await this.keycloak.isLoggedIn();

    if (isLoggedIn) {
      const role = this.keycloak
        .getKeycloakInstance()
        .realmAccess?.roles.filter((role: unknown) => {
          if (
            role !== "offline_access" &&
            role !== "default-roles-workflow" &&
            role !== "uma_authorization" &&
            role !== "committee"
          ) {
            return role;
          }
        });
      if (role instanceof Array) {
        return role;
      }
    }
    return [""];
  }

  async getUserProfile(): Promise<KeycloakProfile> {
    const userProfile = await this.keycloak.loadUserProfile();
    return userProfile;
  }

  logOut(): void {
    this.keycloak.logout();
  }
}

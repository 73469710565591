import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable, Subject, map, of } from "rxjs";
import { User, UserHttpResponse, UserRoleHttpResponse } from "@interfaces/users-keycloak-interface";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  url = `${environment.api}/auth/users`;

  constructor(private http: HttpClient) {}

  getAllUsers(
    first: number = 0,
    max: number = 15,
    country: string = "",
    fullName = ""
  ): Observable<UserHttpResponse | Array<User>> {
    const params = fullName
      ? new HttpParams({ fromObject: { country, fullName } })
      : new HttpParams({ fromObject: { country, first, max } });
    const url = fullName ? `${this.url}/filter` : this.url;

    return this.http.get<UserHttpResponse>(url, { params });
  }

  getAllRoles(): Observable<UserRoleHttpResponse> {
    const url = `${environment.api}/auth/users/roles?page=1&pageSize=1`;
    return this.http.get<UserRoleHttpResponse>(url);
  }

  updateRole(id: string, roles: string[]): Observable<Array<User>> {
    const url = `${environment.api}/auth/users`;
    return this.http.post<Array<User>>(url, {
      id,
      roles,
    });
  }

  removeRole(id: string, roles: string[]): Observable<Array<User>> {
    const url = `${environment.api}/auth/users/${id}`;
    return this.http.delete<Array<User>>(url, {
      body: {
        roles,
      },
    });
  }
}

import { Component, OnInit } from "@angular/core";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from "@angular/router";
import { CountryService } from "@services/country.service";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "../environments/environment";
import { KeycloakService } from "keycloak-angular";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private countryService: CountryService,
    private keyCloak: KeycloakService
  ) {}

  async ngOnInit(): Promise<void> {
    console.log("Env: ", environment.name);

    this.keyCloak.getUserRoles();
  }
}

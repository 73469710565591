import { StrapiService } from '../services/strapi.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CountryStrapiHttpResponse } from '../interfaces/country-strapi-interface';
import { first, Observable } from 'rxjs';

@Injectable()
export class CountriesResolver implements Resolve<CountryStrapiHttpResponse> {
  constructor(private strapi: StrapiService) {}

  resolve(): Observable<CountryStrapiHttpResponse> {
    return this.strapi.getCountries().pipe(first());
  }
}
